import {http} from './config';

export default {
    index: (filter, page = 1, index = false) => {
        if (index){
            return http.get('api/v1/configEmpresa', {params: {page: page,filter}});
        }
        return http.get('api/v1/configEmpresa', {params: filter});
    },
    show: (id) => {
        return http.get(`api/v1/configEmpresa/${id}`);
    },
    update: (data) => {
        return http.put(`api/v1/configEmpresa/${data.id}`, {
            plan_id: data.plan.id,
            number_max_financial_movements_per_sale: data.number_max_financial_movements_per_sale,
            amount_max_per_billet: data.amount_max_per_billet,
            company_name: data.company_name,
            whatsapp_token: data.whatsapp_token,
            company_id: data.company_id,
        });
    }
}
