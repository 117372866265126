<template>
   <div>
      <transition name="modal">
         <div class="modal-mask">
            <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
               <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                     <div class="modal-header modal-header-color">
                        <h5 class="modal-title" id="modalId">{{ t('APP.MODAL.ALLOTMENT') }}</h5>
                        <i class="icon-x" @click="$emit('close')" style="cursor: pointer"></i>
                     </div>
                     <div class="modal-body modal-open">
                        <div class="row gutters">
                           <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12">
                              <div class="form-group">
                                 <input type="text" name="allotment" v-model="allotmentModal" id="allotment"
                                        class="form-control mb-2" @keyup="selectModal"
                                        v-if="!allotmentId"
                                        placeholder="Digite o nome do loteamento para buscar">
                                 <div class="justify-content-center modal-row2">
                                    <div v-for="allotment in allotmentsModal" :key="allotment.id">
                                       <div v-if="allotmentId">
                                          <button class="btn btn-background mt-1" v-if="allotmentId === allotment.id"
                                                  style="background-color: #6c9d84 !important;"
                                                  @click="clearSelection()">
                                             {{ allotment.text }}
                                          </button>
                                       </div>
                                       <button class="btn btn-background mt-1" v-else
                                               @click="allotmentSelected(allotment)">
                                          {{ allotment.text }}
                                       </button>
                                    </div>
                                    <div v-if="allotmentId">
                                       <div class="form-group mt-2">
                                          <label for="mother_name" class="required">{{
                                                t('PANEL.ID_CONFIG_BD')
                                             }}: </label>
                                          <input type="number" id="mother_name" name="mother_name"
                                                 v-model="idConfigBd"
                                                 class="form-control">
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <button class="btn btn-secondary mr-2" type="button" @click="$emit('close')"
                        >{{ t('ACTIONS.CLOSE') }}
                        </button>
                        <button data-cy="client_add_save" class="btn btn-primary" :disabled="!allotmentId"
                                @click="createConfigBd">
                           {{ t('ACTIONS.SAVE') }}
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </transition>
   </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from "vue-i18n";
import {useToast} from 'vue-toastification';
import Allotments from '../../services/Allotments';

export default {
   nome: 'modalAddIdConfigd',
   props: {
      allotmentsIndex: {
         Type: Array,
      }
   },
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },

   computed: {
      ...mapState({
         allotments: 'allotments',
      }),
   },

   data() {
      return {
         aux: [],
         backup: [],
         idConfigBd: null,
         allotmentId: null,
         allotmentsModal: [],
         allotmentModal: null,
      }
   },

   mounted() {
      this.backup = this.allotmentsIndex;
      this.allotmentsModal = this.allotmentsIndex;
   },

   watch: {
      allotmentsIndex() {
         this.backup = this.allotmentsIndex;
         this.allotmentsModal = this.allotmentsIndex;
      }
   },

   created() {
      this.backup = this.allotments;
   },

   methods: {
      selectModal() {
         this.aux = [];
         this.allotmentsModal = this.backup;
         this.allotmentsModal.forEach(allotment => {

            const text = allotment.text.toUpperCase();
            const textModal = this.allotmentModal.toUpperCase();

            if (text.includes(textModal)) {
               this.aux.push(allotment);
            }
         })
         this.allotmentsModal = this.aux;
      },

      allotmentSelected(allotment) {
         this.allotmentId = allotment.id;
         this.idConfigBd = allotment.id_configuracao_bd ? allotment.id_configuracao_bd : null;
      },
      clearSelection() {
         this.allotmentId = null;
         this.idConfigBd = null;
      },
      createConfigBd() {
         if (!this.idConfigBd) {
            this.toast.error('Necessario preencher o campo ID Configuração BD');
            return;
         }
         this.$store.commit('changeLoading', true);
         Allotments.updateIdBd(this.allotmentId, {id_configuracao_bd: this.idConfigBd}).then(() => {
            this.$store.commit('changeLoading', false);
            this.$emit('close');
            this.toast.success(this.t('GENERAL.MSG.UPDATE_SUCCESS'));
         }).catch(() => { this.$store.commit('changeLoading', false)});
      },
   }
}
</script>
